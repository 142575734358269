import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
// import Button from '@material-ui/core/Button'
import Page from '../../components/Page/Page'
import Uploader from '../../components/Uploader/Uploader'
import PlaceIcon from '../../icons/placeIcon'
import MailIcon from '../../icons/mailIcon'
import PhoneIcon from '../../icons/phoneIcon'
import CalendarIcon from '../../icons/calendarIcon'
import './profile.scss'
// import { Colors } from '../../styles'

const Profile = (props) => {

	const [ userData, setUserData ] = useState({})
	const [ isReadOnly, setIsReadOnly ] = useState(true)

	useEffect(() => {
		setUserData(props.auth.userData)
	}, [ props.auth.userData ])

	// const closeProfile = () => {
	//   props.history.goBack();
	// }

	// const toogleReadOnly = () => {
	//   setIsReadOnly(!isReadOnly)
	// }

	const handleSubmit = () => {
		console.log('sendData')
	}

	return (
		<Page>
			<div className='profile'>
				<div className="profile-content">
					<div className='profile-user'>
						<div className='profile-user__info'>
							<p className="profile-user__name">{userData.name} {userData.lastName}</p>
							<form className="profile-user-form" onSubmit={handleSubmit}>
								<div className="profile-user-form__item">
									<PlaceIcon width="27" height="27" />
									<div className="place">
										<input
											value={userData.city || ''}
											onChange={(event) => {setUserData({ ...userData, city: event.target.value })}}
											readOnly={isReadOnly}
											className={isReadOnly ? 'input' : 'input active'}
											placeholder="Город"
										/>
										<input value={userData.country || ''}
											onChange={(event) => {setUserData({ ...userData, country: event.target.value })}}
											readOnly={isReadOnly}
											className={isReadOnly ? 'input' : 'input active'}
											placeholder="Страна"
										/>
									</div>
								</div>
								<div className="profile-user-form__item">
									<MailIcon width="28" height="28" />
									<input value={userData.email || ''}
										onChange={(event) => {setUserData({ ...userData, email: event.target.value })}}
										readOnly={isReadOnly}
										className={isReadOnly ? 'input' : 'input active'}
										placeholder="e-mail"
									/>
								</div>
								<div className="profile-user-form__item">
									<PhoneIcon width="26" height="26" />
									<input value={userData.telNumber || ''}
										onChange={(event) => {setUserData({ ...userData, telNumber: event.target.value })}}
										readOnly={isReadOnly}
										className={isReadOnly ? 'input' : 'input active'}
										placeholder="Телефон +38..."
									/>
								</div>
								<div className="profile-user-form__item">
									<CalendarIcon width={25} height={25} />
									<span className='calendar'>На сайте с {moment(userData.registrDate).format('DD.MM.YY')}</span>
								</div>
							</form>

						</div>
						{
							props.auth.userData.avatarPath
								? <img
									src={props.auth.userData.avatarPath + '?' + Math.random(3)}
									className="profile-user__avatar"
									title="User avatar"
									alt="User avatar"
								/>
								: null // todo: icon
						}
					</div>
					{/* <div className='profile-edit-btn'>
            <Button
              onClick={toogleReadOnly}
              type="submit"
              variant="outlined"
              color="inherit"
              // fullWidth
              // variant="contained"
              // color="primary"
            >
              Изменить
            </Button>
          </div> */}
					<p className="profile-avatar-title">
						{
							userData.avatarPath ? <span>Изменить</span> : <span>Добавить</span>
						}
						<span> фото профиля</span>
					</p>
					<div className="profile-uploader">
						<Uploader />
					</div>
				</div>
			</div>
		</Page>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth
	}
}

export default connect(mapStoreToProps)(Profile)
