import React from 'react'
import Header from '../Header/Header'
import './page.scss'

const Page = ({ onScroll, children }) => {

	return (
		<div onScroll={onScroll} className="page">
			<div className="page__header">
				<Header/>
			</div>
			{children}
		</div>
	)
}

export default Page
