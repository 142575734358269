import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { setLoginWithCredentials } from '../../actions/setLoginWithCredentials'
import { loginWithCredentials } from '../../api'
import Modal from '../../components/Modal/Modal'
import Loader from '../../components/Loader/Loader'
import './loginModal.scss'

const error = 'Обязательное поле'

const useStyles = makeStyles(() => {
	return {
		helperText: {
			color: 'red',
			fontSize: 14
		}
	}
})

const LoginModal = (props) => {
	const classes = useStyles()

	const [ login, setLogin ] = useState('')
	const [ password, setPassword ] = useState('')

	const [ isLoading, setIsLoading ] = useState(false)
	const [ isOpenErrorModal, setOpenErrorModal ] = useState(false)
	const [ isDisabled, setIsDisabled ] = useState(true)

	const [ errorMessage, setErrorMessage ] = useState('')

	const [ loginError, setLoginError ] = useState('')
	const [ passwordError, setPasswordError ] = useState('')

	useEffect(() => {
		if (login && password) {
			setIsDisabled(false)
		} else setIsDisabled(true)
	}, [ login, password ])

	const onInputLogin = (event) => {
		setLogin(event.target.value)
		setLoginError(event.target.value ? '' : error)
	}

	const onInputPassword = (event) => {
		setPassword(event.target.value)
		setPasswordError(event.target.value ? '' : error)
	}

	const sendLoginData = async () => {
		setIsLoading(true)
		const loginData = { login, password }
		const response = await loginWithCredentials(loginData)
		if (response && response.isLogined && response.userData.token) {
			props.dispatch(setLoginWithCredentials(response))
			localStorage.setItem('mfJwt', response.userData.token)
		} else if (response && response.error) {
			setOpenErrorModal(true)
			setErrorMessage(response.error)
		} else {
			setOpenErrorModal(true)
			setErrorMessage('Cервер не отвечает, попробуйте повторить позже')
		}
		setIsLoading(false)
	}

	const closeErrorModal = () => {
		setOpenErrorModal(false)
		setErrorMessage('')
	}

	const openRequestPasswordModal = () => {
		props.closeLoginModal()
		props.openRequestPasswordModal()
	}

	const validate = () => {
		if (login && password) {
			sendLoginData()
		} else {
			setLoginError(!login ? error : '')
			setPasswordError(!password ? error : '')
		}
	}

	if (isOpenErrorModal) {
		return (
			<Modal
				title="Ошибка"
				description={errorMessage}
				okButtonText="Ok"
				onClickOk={closeErrorModal}
			/>
		)
	}

	return (
		<Modal
			title="Вход"
			okButtonText="Отменить"
			onClickOk={props.closeLoginModal}
		>
			{
				isLoading ? <div className="login-modal-loader"><Loader /></div> :
					<div>
						<TextField
							onChange={onInputLogin}
							helperText={loginError}
							FormHelperTextProps={{
								classes:{
									root: classes.helperText
								}
							}}
							fullWidth
							label="Email или телефон"
							value={login}
						/>
						<TextField
							onChange={onInputPassword}
							helperText={passwordError}
							FormHelperTextProps={{
								classes:{
									root: classes.helperText
								}
							}}
							fullWidth
							label="Пароль"
							type="password"
							value={password}
						/>
						<div className="submit">
							<Button
								onClick={validate}
								variant="outlined"
								color="inherit"
								disabled={isDisabled}
							>
              Войти
							</Button>
						</div>
						<p className="autorize-registr">
            Нет пароля для входа?&nbsp;
							<span onClick={openRequestPasswordModal} className="autorize-registr-link">
              Жми сюда
							</span>.
						</p>
					</div>
			}
		</Modal>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth
	}
}

export default connect(mapStoreToProps)(LoginModal)
