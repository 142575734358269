import { API } from './api.js'
import { setLogin } from '../actions/setLogin'
import store from '../store'

const dispatch = store.dispatch

export const loginWithCredentials = async (loginData) => {
	try {
		const response = await API.post({ url: 'login.php', data: loginData })

		return response ? response.data : {}
	}
	catch (error) {
		console.log(error)
	}
}

export const verifyToken = async () => {
	try {
		const response = await API.get({ url: 'validate_token.php' })
		if (response && response.data && response.data.isTokenVerified) {
			dispatch(setLogin(response.data))
		} else {
			dispatch(setLogin({ error:
        'Токен не подтвержден или нет связи с сервером БД. Попробуйте повторить позже.'
				// TODO: add 'Выйти из программы и снова авторизоваться'
			}))
		}
	} catch (error) {
		console.log(error)
		dispatch(setLogin({ error: error }))
	}
}

export const sendRequestPasswordData = async (userData) => {
	try {
		const response = await API.post({ url: 'request_password.php', data: userData })

		return response ? response : {}
	} catch (error) {
		console.log(error)
	}
}

export async function sendRegistrData(userData) {
	try {
		const response = await API.post({ url: 'registration.php', data: userData })
		if (response.data.token && response.data.successSendEmail) {
			localStorage.setItem('mfJwt', response.data.token)
		}

		return response.data
	} catch (error) {
		console.log(error)
	}
}
