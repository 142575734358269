import * as api from './request'

const post = async ({ url, data, headers = [] }) => {
	return await api.request({
		method: 'POST',
		resource: url,
		data: data,
		headers: headers
	})
}

const get = async ({ url, headers = [] }) => {
	return await api.request({
		method: 'GET',
		resource: url,
		headers: headers
	})
}

const patch = async ({ url, data, headers = [] }) => {
	return await api.request({
		method: 'PATCH',
		resource: url,
		data: data,
		headers: headers
	})
}

const put = async ({ url, data, headers = [] }) => {
	return await api.request({
		method: 'PUT',
		resource: url,
		data: data,
		headers: headers
	})
}

const remove = async ({ url,headers = [] }) => {
	return await api.request({
		method: 'DELETE',
		resource: url,
		headers: headers
	})
}

export const API = {
	post,
	patch,
	get,
	remove,
	put
}
