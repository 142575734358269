import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import heic2any from 'heic2any'
import TextField  from '@material-ui/core/TextField'
import { resetUnreadMessages } from '../../actions/resetUnreadMessages'
import { setLastLoadedPostId } from '../../actions/setLastLoadedPostId'
import { setPostsCache } from '../../actions/setPostsCache'
import { setScrollPosition } from '../../actions/setScrollPosition'
import {
	deletePost, fetchMorePosts, fetchPosts, uploadMediaFile
} from '../../chatApi/chatApi'
import FileUploadModal from '../../components/FileUploadModal/FileUploadModal'
import Loader from '../../components/Loader/Loader'
import Menu from '../../components/Menu/Menu'
import MessageInput from '../../components/MessageInput/MessageInput'
import Modal from '../../components/Modal/Modal'
import Page from '../../components/Page/Page'
import Post from '../../components/Post/Post'
import { createPost } from '../../helpers/createPost'
import { throttle } from '../../helpers/throttle'
import ArrowDownIcon from '../../icons/arrowDown'
import AttachIcon from '../../icons/attachIcon'
import './mainPage.scss'

const offsetContextMenu = 10 // for onMouseOut
const postsLimit = 10
const imageSizeLimit = 3 * 1000 * 1000 // 3 Mb
const videoSizeLimit = 40 * 1000 * 1000 // 40 Mb

const TestPage = (props) => {

	const { dispatch, posts } = props
	const { id: userId } = props.auth.userData

	const contextMenuRef = useRef(null)
	const inputWrapRef = useRef(null)
	const fileUploadRef = useRef(null)

	const [ errorMessage, setErrorMessage ] = useState('')
	const [ isEditMode, setIsEditMode ] = useState(false)
	const [ isDisabledScrolling, setDisableScrolling ] = useState(false)
	const [ isLoading, setIsLoading ] = useState(false)
	const [ isOpenErrorModal, setOpenErrorModal ] = useState(false)
	const [ isShowContextMenu, setShowContextMenu ] = useState(false)
	const [ isShowDeleteModal, setShowDeleteModal ] = useState(false)
	const [ isShowFileUploadModal, setShowFileUploadModal ] = useState(false)
	const [ isShowScrollDownBtn, setShowScrollDownBtn ] = useState(false)
	const [ menuCoordinates, setMenuCoordinates ] = useState({})
	const [ message, setMessage ] = useState('')
	const [ postMedia, setPostMedia ] = useState({})
	const [ selectedPost, setSelectedPost ] = useState({})

	/* Loading first posts */
	useEffect(() => {
		if (posts.cachedPosts && posts.cachedPosts.length > 0) {
			const page = document.querySelector('.page') // from <Page />
			if (posts.scrollPosition) {
				page.scrollTo({ top: posts.scrollPosition })
			}

			return
		}
		setIsLoading(true)
		fetchPosts(
			postsLimit,
			initialPosts => {
				dispatch(setPostsCache(initialPosts))
				dispatch(setLastLoadedPostId(initialPosts[0].id))
				setIsLoading(false)
				scrollUp()
			}
		)
	}, [ dispatch ])

	useEffect(() => {
		if (posts.unreadMessages > 0) {
			scrollUp({ behavior: 'smooth' } )
			dispatch(resetUnreadMessages())
		}
	}, [ dispatch, posts.unreadMessages ])

	/* To position the context menu correctly: */
	useEffect(() => {
		if (contextMenuRef.current) {

			const { offsetWidth, offsetHeight } = contextMenuRef.current
			const { offsetHeight: inputOffset } = inputWrapRef.current
			const { clientWidth, clientHeight } = document.documentElement

			contextMenuRef.current.style.left = (menuCoordinates.x + offsetWidth) < clientWidth
				? menuCoordinates.x - offsetContextMenu + 'px'
				: menuCoordinates.x - offsetWidth + offsetContextMenu + 'px'

			contextMenuRef.current.style.top = (menuCoordinates.y + offsetHeight + inputOffset) < clientHeight
				? menuCoordinates.y - offsetContextMenu + 'px'
				: menuCoordinates.y - offsetHeight + offsetContextMenu + 'px'
		}
	}, [ menuCoordinates ])

	const closeContextMenu = () => {
		setSelectedPost({})
		setShowContextMenu(false)
	}

	const closeEditMode = () => {
		setIsEditMode(false)
		setSelectedPost({})
	}

	const closeErrorModal = () => {
		setErrorMessage('')
		setOpenErrorModal(false)
	}

	const closeModal = () => {
		setSelectedPost({})
		setShowDeleteModal(false)
	}

	const cancelUploadModal = () => {
		setPostMedia({})
		setShowFileUploadModal(false)
	}

	const closeUploadModal = () => {
		setMessage('')
		cancelUploadModal()
	}

	const createContextMenu = () => {
		return [
			{ name: 'Комментировать', action: undefined },
			{ name: 'Копировать', action: undefined },
			(selectedPost && selectedPost.senderId === userId && { name: 'Редактировать', action: setMessageForEdit }),
			(selectedPost && selectedPost.senderId === userId && { name: 'Удалить', action: openDeleteModal })
		]
	}

	const setCurrentPost = (postId) => {
		if (postId) {
			const currentPost = posts.cachedPosts.find(post => post.id === postId)
			setSelectedPost(currentPost)
		}
	}

	const handleScroll = () => {
		const page = document.querySelector('.page')
		if (isDisabledScrolling || !page) {
			setDisableScrolling(false)
			return
		}

		const { scrollTop, scrollHeight, clientHeight } = page
		const scrollBottom = scrollHeight - scrollTop - clientHeight

		if (scrollTop <= 0.2 * scrollHeight) {
			loadMorePosts()
		}

		if (scrollBottom > 400) {
			setShowScrollDownBtn(true)
		} else {
			setShowScrollDownBtn(false)
		}

		dispatch(setScrollPosition(scrollTop))
	}

	const handleUploadFile = (event) => {
		event.preventDefault()

		if (fileUploadRef.current) {
			fileUploadRef.current.addEventListener('click', () => {
				fileUploadRef.current.value = ''
			})
		}

		if (event.target.files && event.target.files.length) {
			const src = URL.createObjectURL(event.target.files[0])//  URL.createObjectURL может вызвать утечку памяти, если не освобождать ресурсы после использования, когда компонент размонтируется или изображение изменяется
			const uploadFile = event.target.files[0]
			const fileNameParts = uploadFile.name.split('.')
			const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

			if (
				(uploadFile.type.includes('image') && uploadFile.size >= imageSizeLimit) ||
        (fileExtension === 'heic' && uploadFile.size >= imageSizeLimit) ||
        (uploadFile.type.includes('video') && uploadFile.size >= videoSizeLimit)
			) {
				showErrorModal('Слишком большой размер файла')
				return
			}

			if (fileExtension === 'heic') {
				convertHeicToWebp(uploadFile.name, uploadFile)
			} else if (uploadFile.type.includes('image')) {
				convertFileToWebp(uploadFile.name, src)
			} else if (uploadFile.type.includes('video')) {
				showErrorModal('Загрузка видео пока не поддерживается')
			} else {
				showErrorModal('Этот тип файла пока не поддерживается')
			}
		}
	}

	const convertHeicToWebp = (fileName, file) => {
		const reader = new FileReader()
		reader.onload = function (e) {
			const heicData = new Uint8Array(e.target.result)
			heic2any({ blob: new Blob([ heicData ], { type: 'image/heic' }), toType: 'image/webp', quality: 0.7 })
				.then(function (blob) {
					convertHeicToBase64(fileName, blob)
				})
				.catch(function (error) {
					console.error('Convert .heic to Base64 error', error)
				})
		}
		reader.readAsArrayBuffer(file)
	}

	const convertHeicToBase64 = (fileName, blob) => {
		let reader = new FileReader()
		reader.readAsDataURL(blob) // convert Blob to base64
		reader.onload = function() {
			convertFileToWebp(fileName, reader.result)
		}
	}

	const convertFileToWebp = (uploadFileName, src) => {
		// Convert User Image to Canvas
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		const userImage = new Image()
		userImage.src = src
		userImage.onload = () => {
			canvas.width = userImage.width
			canvas.height = userImage.height
			ctx.drawImage(userImage, 0, 0)

			// Convert Canvas to Webp
			const webpImage = canvas.toDataURL('image/webp') // or ('image/webp', 1) for hi quality

			// display converted User Image
			setPostMedia({ fileName: uploadFileName.split('.')[0] + '.webp', image: webpImage })
			setShowFileUploadModal(true)
		}
	}

	const uploadMedia = () => {
		setIsLoading(true)
		setDisableScrolling(true)
		uploadMediaFile(postMedia, (downloadUrl) => {
			downloadUrl && createPost(message, downloadUrl, props.auth.userData)
			setDisableScrolling(false)
			setIsLoading(false)
			scrollUp()
			closeUploadModal()
		})
	}

	const loadMorePosts = async () => {
		if (posts.lastLoadedPostId && !isDisabledScrolling) {
			const newPosts = await fetchMorePosts(posts.lastLoadedPostId)
			console.log('morePosts', newPosts)
			if (newPosts.length > 0) {
				dispatch(setPostsCache(newPosts))
				dispatch(setLastLoadedPostId(newPosts[0].id))
			} else {
				dispatch(setLastLoadedPostId(null))
			}
		}
	}

	const openContextMenu = (event, postId) => {
		setCurrentPost(postId)
		setShowContextMenu(true)
		if (event.touches) {
			setMenuCoordinates({ x: event.touches[0].clientX, y: event.touches[0].clientY })
		}
		else if (event.currentTarget) {
			event.preventDefault()
			setMenuCoordinates({ x: event.pageX, y: event.pageY })
		}
	}

	const openDeleteModal = () => {
		setShowDeleteModal(true)
	}

	const onInput = (value) => {
		setMessage(typeof value === 'string' ? value : value.target.value)
	}

	const removePost = async () => {
		if (selectedPost.id) {
			await deletePost(selectedPost)
			closeContextMenu()
			setShowDeleteModal(false)
		}
	}

	const scrollUp = (effect) => {
		const page = document.querySelector('.page') // from <Page />
		if (page) {
			if (effect) {
				page.scrollTo({ ...effect, top: page.scrollHeight })
			} else {
				page.scrollTo({ top: page.scrollHeight })
			}
		}
	}

	const setEditMode = (isEditMode) => {
		setIsEditMode(isEditMode)
		if (!isEditMode) {
			setSelectedPost({})
		}
	}

	const setMessageForEdit = () => {
		setIsEditMode(true)
		setShowContextMenu(false)
	}

	const showErrorModal = (errorText) => {
		setErrorMessage(errorText)
		setOpenErrorModal(true)
	}

	return (
		<Page onScroll={throttle(handleScroll, 400)}>
			{
				isLoading
					? <Loader color="inherit" />
					: <div className='posts-container'>
						<ul>
							{
								posts.cachedPosts.length > 0 &&
                posts.cachedPosts.map(post => {
                	return (
                		<li key={post.id} className='posts-container__item'>
                			<Post
                				post={post}
                				openContextMenu={openContextMenu}
                				scrollUp={scrollUp}
                			/>
                		</li>
                	)
                })
							}
						</ul>
						{
							isShowContextMenu &&
              <div ref={contextMenuRef} className="posts-container__context-menu">
              	<Menu
              		close={closeContextMenu}
              		menuItems={createContextMenu()}
              		isOpen={isShowContextMenu}
              	/>
              </div>
						}
					</div>
			}
			<div ref={inputWrapRef} className="input-wrap">
				<div className="input">
					{
						isEditMode &&
            <div className="edit-mode">
            	<span className="edit-title">Редактирование</span>
            	<span className="edit-close" onClick={closeEditMode}>Отменить</span>
            </div>
					}
					<MessageInput
						scrollUp={scrollUp}
						selectedPost={selectedPost}
						isEditMode={isEditMode}
						setEditMode={setEditMode}
						message={message}
						onInputChange={onInput}
					/>
				</div>
				<div className='main-page__btn-container'>
					{
						isShowScrollDownBtn &&
            <div onClick={() => scrollUp({ behavior: 'smooth' })} className="main-page__scroll-down">
            	<ArrowDownIcon width="34" height="34" />
            </div>
					}
					<label className="main-page__attach-button">
						<input
							ref={fileUploadRef}
							type="file"
							className="main-page__input-upload"
							accept="video/*, image/*, .heic, .pdf"
							onChange={handleUploadFile}
						/>
						<AttachIcon width="24" height="24" />
					</label>
				</div>
			</div>
			{
				isShowDeleteModal &&
        <Modal
        	title="Удаление сообщения"
        	description="Удалить это сообщение?"
        	okButtonText="Удалить"
        	cancelButtonText="Отменить"
        	onClickOk={removePost}
        	onClickCancel={closeModal}
        />
			}
			{
				(isOpenErrorModal && errorMessage) &&
        <Modal
        	title="Sorry"
        	description={errorMessage}
        	cancelButtonText="Закрыть"
        	onClickCancel={closeErrorModal}
        />
			}
			{
				isShowFileUploadModal &&
        <FileUploadModal
        	title="Создание публикации"
        	okButtonText="Отправить"
        	cancelButtonText="Отменить"
        	onClickOk={uploadMedia}
        	onClickCancel={cancelUploadModal}
        >
        	{
        		!isLoading &&
            <TextField
            	multiline
            	// maxRows="10"
            	maxrows="10"
            	value={message}
            	placeholder="Message"
            	fullWidth
            	size="small"
            	className="upload-modal__message"
            	onChange={onInput}
            />
        	}
        	{
        		isLoading
        			? <Loader />
        			: <img
        				src={postMedia.image ? postMedia.image : null}
        				alt="preview"
        			/>
        	}
        </FileUploadModal>
			}
		</Page>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth,
		posts: store.posts
	}
}

export default connect(mapStoreToProps)(TestPage)
