import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Page from '../../components/Page/Page'
// import { formatDate } from '../../helpers/formatDate'
import './video.scss'
import  { getList, listRef } from '../../firebase'

const Video = (props) => {
	// console.log(props.auth)
	const [ resourceArray, setResourceArray ] = useState([])

	useEffect(() => {
		getVideoList()
	}, [])

	const getVideoList = async () => {
		try {
			// Fetch the first page of 100.
			const firstPage = await getList(listRef, { maxResults: 10 })
			setResourceArray(firstPage.items)
		} catch (error) {
			console.log('Error: ' + error)
		}
	}

	// console.log('items', resourceArray)

	return (
		<Page>
			<div  className="container">
				<div className="video-list">
					{
						resourceArray.map((item, index) => {
							const itemPath = encodeURIComponent(item.fullPath)
							return (
								<div className='video-list-item' key={index}>
									<p className='video-list-item-title'>{item.name}</p>

									{/* TODO: Задать width и height для <video> для оптимизации */}
									<video
										className='video-list-item-img'
										allowFullScreen controls
										// preload="none"
										src={`https://${item._service._host}/v0/b/${item.bucket}/o/${itemPath}?alt=media`}
									>
									</video>
									{/* <p className='video-list-item-date'>{formatDate(item.LastModified)}</p> */}
								</div>
							)
						})
					}
					<h1>Video-card 2</h1>
					<h1>Video-card 2</h1>
					<h1>Video-card 2</h1>
					<h1>Video-card 2</h1>
				</div>
			</div>
		</Page>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth
	}
}

export default connect(mapStoreToProps)(Video)
