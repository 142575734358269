import React from 'react'
import './fileUploadModal.scss'

const FileUploadModal = (props) => {
	return (
		<div className='upload-modal'>
			<div className="upload-modal__container">
				<h4 className="upload-modal__title">{props.title}</h4>
				<div className="upload-modal__content">
					{props.children}
				</div>
				<div className='upload-modal__buttons'>
					{ props.cancelButtonText &&
            <span
            	onClick={props.onClickCancel}
            	className="upload-modal__button upload-modal__button--cancel"
            >
            	{props.cancelButtonText}
            </span>
					}
					{ props.okButtonText &&
            <span
            	onClick={props.onClickOk}
            	className="upload-modal__button upload-modal__button--ok"
            >
            	{props.okButtonText}
            </span>
					}
				</div>
			</div>
		</div>
	)
}

export default FileUploadModal
