import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { verifyToken } from './api'
import Loader from './components/Loader/Loader'
import StartPage from './pages/StartPage/StartPage'
import MainPage from './pages/MainPage/MainPage'
import Table from './pages/Table/Table'
import Registration from './pages/Registration/Registration'
import Video from './pages/Video/Video'
import Profile from './pages/Profile/Profile'
import Page404 from './pages/Page404/Page404'
import { listenForAddChild, listenForChangeChild, listenForRemoveChild } from './chatApi/chatApi'
import { updatePostCache } from './actions/updatePostCache'
import { setUnreadMessage } from './actions/setUnreadMessage'
import { ADD_POST_CACHE, REMOVE_POST_CACHE, UPDATE_POST_CACHE } from './actions/types'
import store from './store'

const App = (props)=> {

	const { dispatch, auth } = props

	const [ isLogined, setIsLogined ] = useState(false)
	const [ isLoading, setIsLoading ] = useState(true)

	useEffect(() => {
		if (localStorage.mfJwt) {
			verifyToken()
		}
	}, [])

	useEffect(()=> {
		if (auth.isLogined) {
			setIsLogined(true)
		} else {
			setIsLogined(false)
		}
		setIsLoading(false)
	}, [ auth.isLogined, auth.error ])

	/* Listener for add new post */
	useEffect(() => {
		const subscribeAddPost = listenForAddChild(newPost => {
			dispatch(updatePostCache(ADD_POST_CACHE, newPost))
			if (newPost.senderId !== store.getState().auth.userData.id) {
				dispatch(setUnreadMessage())
			}
		})
		return () => subscribeAddPost()
	}, [ dispatch ])

	/* Listener for post changes */
	useEffect(() => {
		const subscribe = listenForChangeChild(updatedPost => {
			if (updatedPost) {
				dispatch(updatePostCache(UPDATE_POST_CACHE, updatedPost))
			}
		})
		return () => subscribe()
	}, [ dispatch ])

	/* Listener for remove post */
	useEffect(() => {
		const subscribe = listenForRemoveChild(removedPost => {
			if (removedPost) {
				dispatch(updatePostCache(REMOVE_POST_CACHE, removedPost))
			}
		})
		return () => subscribe()
	}, [ dispatch ])

	if (isLoading) {
		return <Loader />
	}

	return (
		!isLogined && !localStorage.mfJwt
			? <BrowserRouter>
				<Switch>
					<Route exact path="/" component={StartPage}/>
					<Route exact path="/table" component={Table}/>
					<Route exact path="/registration" component={Registration}/>
					{/* <Route component={Page404}/> */}
				</Switch>
			</BrowserRouter>
			: <BrowserRouter>
				<Switch>
					<Route exact path='/main' component={MainPage} />
					<Route exact path='/video' component={Video} />
					<Route exact path='/profile' component={Profile} />
					<Route exact path="/table" component={Table}/>
					<Route exact path='/' component={MainPage} />
					<Route component={Page404}/>
				</Switch>
			</BrowserRouter>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth
	}
}

export default connect(mapStoreToProps)(App)
