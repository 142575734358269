import React from 'react'

const IconVideo = (props) => {

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="5 6 80 80"
			width={`${props.size}px`} height={`${props.size}px`}
			className={props.className}
			strokeWidth="1"
		>
			<g>
				<path d="M41.884,34.988c-0.879-0.411-1.946-0.548-1.946,0.813v20.626c0,1.257,0.962,1.431,1.955,0.848l18.13-10.062   c1.295-0.627,0.98-1.708-0.088-2.216L41.884,34.988z"/>
				<path d="M66.721,79.158c0,0-6.084-3.318-12.893-4.318v-4.23h24.977c2.979,0,4.436-1.065,4.436-4.047V24.241   c0-2.978-1.514-3.581-4.494-3.581l-61.999,0c-2.975,0-3.988,1.274-3.988,4.248V66.42c0,2.981,0.951,4.189,3.923,4.189h25.586v4.23   c-6.529,0.907-11.931,3.892-11.931,4.299L66.721,79.158z M78.551,63.433c0,2.553-0.596,2.669-2.369,2.669H19.673   c-1.685,0-2.227-0.366-2.227-2.922V27.913c0-2.546,0.185-2.746,2.286-2.746h56.022c2.578,0,2.797,0.263,2.797,2.114V63.433z"/>
			</g>
		</svg>
	)
}
export default IconVideo
