import axios from 'axios'
import { config } from '../config'
import { transformResponse } from './transformResponse'

const token = localStorage.getItem('mfJwt')

const defaultHeaders = () => {
	const headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
		'Authorization': `Bearer ${token}`
	}
	return headers
}

const request = async ({ method, resource, data, headers }) => {
	try {
		// console.log('request:' + method + ' resourse:' + resource + ' data:' + JSON.stringify(data) + ' headers:' + JSON.stringify(headers))

		const result = await axios({
			method: method,
			url: `${config.API_URL}${resource}`,
			data: JSON.stringify(data),
			headers: { ...defaultHeaders(), ...headers }
		})

		return transformResponse({
			method,
			resource,
			status: result && result.status,
			// json: result.data ? result.data : { result: false },
			responseData: result && result.data
		})
	} catch (err) {
		console.log('requestError: ', err)
	}
}

export {
	request
}
