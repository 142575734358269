import React, { useEffect, useRef, useState } from 'react'
import { Avatar } from '@material-ui/core'
import moment from 'moment'
import { getTimeAgo } from '../../helpers/formatDate'
import './post.scss'

const timeAgoLimit = 4 * 43200000 // 2 twenty-four hours
const timeAgoInterval = 30 * 1000 // 30 sec

const maxCharacters = 150
const touchDelay = 500

const Post = (props) => {

	const { senderAvatarPath, isDelivery, message, mediaUrl, id, publicationDate, senderName, senderLastName } = props.post

	const [ currentTime, setCurrentTime ] = useState(new Date().getTime())
	const [ expanded, setExpanded ] = useState(false)

	const postContentRef = useRef(null)
	const touchStartRef = useRef(null)

	const localPublicationDate = publicationDate
		? moment.parseZone(publicationDate).local().format('DD.MM.YY')
		: ''

	const formattedDate = (currentTime - moment(publicationDate).valueOf()) < timeAgoLimit
		? getTimeAgo(publicationDate)
		: localPublicationDate

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date().getTime())
		}, timeAgoInterval)

		return () => clearInterval(interval)
	}, [ currentTime ])

	useEffect(() => {
		const postElement = postContentRef.current
		postElement.addEventListener('touchstart', handleTouchStart, false)

		return () => postElement.removeEventListener('touchstart', handleTouchStart)
	}, [])

	const handleTouchStart = (event) => {
		event.preventDefault()
		touchStartRef.current = setTimeout(() => {
			props.openContextMenu(event, id)
		}, touchDelay)
	}

	const handleTouchEnd = () => {
		clearTimeout(touchStartRef.current)
	}

	const onLoad = () => {
		props.scrollUp()
	}

	const renderMessage = () => {
		if (expanded) {
			return message
		} else {
			return message.length < maxCharacters
				? message
				: message.slice(0, maxCharacters) + '....'
		}
	}

	const toggleMessage = () => {
		setExpanded(!expanded)
	}

	const seeMoreText = expanded ? ' (показ.меньше)' : ' Ещё'

	return (
		<div className='post'>
			<div className="post-avatar">
				<Avatar src={senderAvatarPath + '?' + Math.random(3)} alt={`${senderName} ${senderLastName}`} />
			</div>
			<div
				ref={postContentRef}
				onTouchEnd={handleTouchEnd}
				onContextMenu={(event) => props.openContextMenu(event, id)}
				className='post-content'
			>
				<div className="post-author">
					<span className="post-author__name">{senderName} {senderLastName}</span>
					<span className="post-author__date">{formattedDate}</span>
				</div>
				<p className='post-message'>
					<span>{renderMessage()}</span>
					{
						message.length > maxCharacters &&
						<span className='post-message__see-more' onClick={toggleMessage}>{seeMoreText}</span>
					}
				</p>
				{
					mediaUrl &&
					<img src={mediaUrl} className="post-media" alt={mediaUrl && 'Photo'} onLoad={onLoad} />
				}
				{
					<span className={`post__check-delivery ${!isDelivery && 'post__check-delivery--error'}`}>
						&#10003; {!isDelivery && '!'}
					</span>
				}
			</div>
		</div>
	)
}

export default Post
