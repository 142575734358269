import moment from 'moment'
import { addPost, updatePost } from '../chatApi/chatApi'

export const createPost = async (message, downloadUrl, userData) => {
	const publicationDate = moment.utc().format() // TODO: refactor to ms or firebase time
	let post = {
		isDelivery: false,
		mediaUrl: downloadUrl ? downloadUrl : '',
		message,
		publicationDate,
		senderAvatarPath: userData.avatarPath,
		senderId: userData.id,
		senderLastName: userData.lastName,
		senderName: userData.name
	}
	const response = await addPost(post)
	if (response && response.key) {
		await updatePost(response.key, '/isDelivery', true)
	} else {
		console.log('Database is not available')
	}
}
