import React, { useState, useEffect } from 'react'
import Loader from '../../components/Loader/Loader'
import LoginModal from '../LoginModal/LoginModal'
import PasswordRequestModal from '../PasswordRequestModal/PasswordRequestModal'
import './startPage.scss'

const StartPage = (props) => {
	const [ isLoading, setIsLoading ] = useState(true)
	const [ innerHeight, setInnerHeight ] = useState(window.innerHeight)
	const [ isOpenLoginModal, setOpenLoginModal ] = useState(false)
	const [ isOpenRequestPasswordModal, setOpenRequestPasswordModal ] = useState(false)

	useEffect(() => {
		setIsLoading(false)
	}, [])

	const onResize = () => {
		setInnerHeight(window.innerHeight)
	}

	window.onresize = onResize

	const openLoginModal = () => {
		closeRequestPasswordModal()
		setOpenLoginModal(true)
	}

	const openRequestPasswordModal = () => {
		closeLoginModal()
		setOpenRequestPasswordModal(true)
	}

	const closeLoginModal = () => {
		setOpenLoginModal(false)
	}

	const closeRequestPasswordModal = () => {
		if (isOpenRequestPasswordModal) setOpenRequestPasswordModal(false)
	}

	return (
		isLoading ? <Loader /> :
			<div style={{ height: innerHeight }} className="start-page">
				<div className="start-page__header">
					<span className="start-page__title">kiiga 89 mf</span>
					<img
						src="entry.png"
						className="start-page__img"
						alt="Site entry"
						onClick={openLoginModal}
					/>
				</div>
				{
					isOpenLoginModal &&
        <LoginModal
        	closeLoginModal={closeLoginModal}
        	openRequestPasswordModal={openRequestPasswordModal}
        >
        	{props.children}
        </LoginModal>}
				{
					isOpenRequestPasswordModal &&
        <PasswordRequestModal
        	closeRequestPasswordModal={closeRequestPasswordModal}
        	openRequestPasswordModal={openRequestPasswordModal}
        	openLoginModal={openLoginModal}
        >
        	{props.children}
        </PasswordRequestModal>
				}
			</div>
	)
}

export default StartPage
