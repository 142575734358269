import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import InputAdornment   from '@material-ui/core/InputAdornment'
import TextField  from '@material-ui/core/TextField'
import { updatePost } from '../../chatApi/chatApi'
import { createPost } from '../../helpers/createPost'
import ArrowUpIcon from '../../icons/arrowUp'
import './messageInput.scss'

const MessageInput = (props) => {
	const { isEditMode, selectedPost, setEditMode, message, onInputChange } = props

	const inputRef = useRef(null)

	const [ isDisabled, setIsDisabled ] = useState(true)

	useEffect(() => {
		if (isEditMode && selectedPost.message) {
			onInputChange(selectedPost.message)
			inputRef.current.focus()
		} else {
			onInputChange('')
			setIsDisabled(true)
		}
	}, [ isEditMode ])

	const closeEditMode = () => {
		onInputChange('')
		setEditMode(false)
		setIsDisabled(true)
	}

	const onBlur = () => {
		if (isEditMode && !message) {
			setEditMode(false)
		}
	}

	const onInput = (event) => {
		onInputChange(event.target.value)
		setIsDisabled(!event.target.value)
	}

	const runMessageAction = async (event, downloadUrl) => {
		if (isEditMode && message ) {
			updateMessage()
		} else {
			(message) && await createPost(message, downloadUrl, props.auth.userData )
			props.scrollUp()
			onInputChange('')
			setIsDisabled(true)
		}
	}

	const updateMessage = async () => {
		if (selectedPost.id) {
			await updatePost(selectedPost.id, '/message', message)
			closeEditMode()
		}
	}

	return (
		<TextField
			inputRef={inputRef}
			multiline
			maxRows="10"
			// maxrows="10"
			value={message}
			placeholder="Сообщение..."
			variant="outlined"
			fullWidth
			size="small"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Button
							aria-label="send"
							className="main-page__send-button"
							startIcon={<ArrowUpIcon width="42px" height="42px"/>}
							disabled={isDisabled}
							onClick={runMessageAction}
						>
						</Button>
					</InputAdornment>
				)
			}}
			onChange={onInput}
			onBlur={onBlur}
		/>
	)
}

const mapStoreToProps = (store) => {
	return {
		auth: store.auth
	}
}

export default connect(mapStoreToProps)(MessageInput)
