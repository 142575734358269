import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import './loader.scss'

const Loader = (props) => {

	return (
		<div className="loader-container">
			<CircularProgress
				color={props.color}
			/>
		</div>
	)
}

export default Loader
