export const config = {
	API_URL: './server/',
	API_Youtube: 'https://www.googleapis.com/youtube/v3/'
	// youTubeChannelId: "UCpVxQpy3IBW_GqV1IA7PT3w",
	// googleApiKey: 'AIzaSyBTsPGmqm1YCmsRbD65fQH8I9C-9HBXvTw',
	// projectId: 'mf89-334216',

	// SECRET_KEY: '_!)(@#&^$%^',
	// PUSH_AUTHORIZATION_KEY:
	// 'AAAAKKuWA3E:APA91bHCk4efx-Gbv2AzQuihgKv88t0LQS0HrdB2c595H4vmJ_81Q2lmoAhsfWFoTRA_lvUBBHIO2_x8m18UHmwPNmWZkx05WrzK2OhfVtDeRUUpv6pycxHQX19-oDs5rk4pfyZd2uW_',
}
