import React, { useEffect } from 'react'
import './menu.scss'

const Menu = (props) => {
	useEffect(() => {
		document.addEventListener('touchstart', closeMenu)

		return () => document.removeEventListener('touchstart', closeMenu)
	}, [])

	const closeMenu = (event) => {
		const element = event.targetTouches[0].target
		if (!(element.classList.contains('menu') || element.classList.contains('menu__item')) && props.isOpen) {
			props.close()
		}
	}

	return (
		<ul onMouseLeave={props.close} className='menu'>
			{
				props.menuItems.map((item, index) => {
					const isRemoveItem = item.name === 'Удалить'
					return (
						item.name &&
            <li
            	key={index}
            	className={`menu__item ${isRemoveItem && 'menu__item--remove'}`}
            	onClick={item.action}
            >
            	{item.name}
            </li>
					)
				})
			}
		</ul>
	)
}

export default Menu
