import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import './page404.scss'

const Page404 = () => {
	return (
		<div className="page404">
			<h2 className="page404-title">Кто-то потерялся, ты или мы..</h2>
			<div className="page404-img"></div>
			<Link to="/main" className="page404-link">
				<Button variant="outlined"
					color="inherit">
          Вернуться на главную
				</Button>
			</Link>
			<h2 className="page404-title">Ошибка 404, страница не найдена</h2>
			<h2 className="page404-title">Возможно ты набрал неверный адрес</h2>
		</div>
	)
}
export default Page404
