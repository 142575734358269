export const transformResponse = ({ method, resource, status, responseData }) => {
	console.log(`%c ${resource}/${method}`, 'color: green', responseData)
	try {
		switch (resource) {
		// case 'auth/login':
		// return transformers.loginTransformer({ json })
		default:
			return {
				isSuccess: status === 200 || status === 201,
				// data: json
				data: responseData
			}
		}
	} catch (error) {
		console.log(`%c ${resource}/${method}`, 'color: red', error)
		return {
			isSuccess: false,
			data: error
		}
	}
}
