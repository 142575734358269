import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import { ref, uploadString, getDownloadURL, deleteObject } from 'firebase/storage'
import { firebaseConfig, storage } from '../firebase'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// Initialize Realtime Database and get a reference to the service
const database = firebase.database()
const databaseRef = database.ref('posts/')
const tableDatabaseRef = database.ref('tableData/')

export const listenForChangeChild = (callback) => {
	const listener = databaseRef.on('child_changed', (data) => {
		const updatedChild = { id: data.key, ...data.val() }
		callback(updatedChild)
	})

	return () => {
		databaseRef.off('child_changed', listener)
	}
}

export const listenForAddChild = (callback) => {
	let initialDataLoaded = false
	const listener = databaseRef.on('child_added', (data) => {
		if (initialDataLoaded) {
			const newChild = { id: data.key, ...data.val() }
			callback(newChild)
		}
	}, (error) => {
		console.log('database access error_1', error)
	})

	databaseRef.once('value', (snapshot) => {
		initialDataLoaded = true
	}, (error) => {
		console.log('database access error_2', error)
	})

	return () => {
		databaseRef.off('child_added', listener)
	}
}

export const listenForRemoveChild = (callback) => {
	const listener = databaseRef.on('child_removed', (data) => {
		const removedChild = { id: data.key, ...data.val() }
		callback(removedChild)
	})

	return () => {
		databaseRef.off('child_removed', listener)
	}
}

export const fetchPosts = async (limit, callback) => {
	try {
		const snapshot = await databaseRef.limitToLast(limit).once('value')
		const postsData = snapshot.val()
		const postsArray = postsData ? Object.entries(postsData).map(([ id, data ]) => ({ id, ...data })) : []
		callback(postsArray)
	} catch (error) {
		console.error('Error fetching posts:', error)
	}
}

export const fetchMorePosts = async (lastLoadedPostId) => {
	try {
		const snapshot = await databaseRef.orderByKey().endBefore(lastLoadedPostId).limitToLast(5).once('value')
		const postsData = snapshot.val()
		return postsData ? Object.entries(postsData).map(([ id, data ]) => ({ id, ...data })) : []
	} catch (error) {
		console.error('Error fetching posts:', error)
		return []
	}
}

export const addPost = async (post) => {
	try {
		// await databaseRef.push({ timestamp: firebase.database.ServerValue.TIMESTAMP })
		const key = await databaseRef.push(post)
		return key

	} catch (error) {
		console.error('Error adding post:', error)
	}
}

export const updatePost = async (key, path, value) => {
	try {
		const updates = {}
		updates[key + path] = value
		await databaseRef.update(updates, () => {
			console.log('Post value updated successfully')
		})
	} catch (error) {
		console.error('Error updating post:', error)
	}
}

export const deletePost = async (post) => {
	try {
		await databaseRef.child(post.id).remove()
		console.log('Post deleted successfully')
		if (post.mediaUrl) {
			removePostMedia(post)
		}
	} catch (error) {
		console.error('Post deletion error:', error)
	}
}

export const removePostMedia = (post) => {
	const fileName = decodeURIComponent(post.mediaUrl).split('/').pop().split('?')[0]
	const chatMediaRef = ref(storage, `chat-media/${fileName}`)
	deleteObject(chatMediaRef)
		.then(() => { console.log('File deleted successfully') })
		.catch((error) => { console.log('File deletion error', error) })
}

export const uploadMediaFile = (image, callback) => {
	if (image.image) {
		const chatMediaRef = ref(storage, `chat-media/${image.fileName}`)
		uploadString(chatMediaRef, image.image, 'data_url')
			.then((snapshot) => {
				console.log('Uploaded a file')
				return getDownloadURL(snapshot.ref)
			})
			.then((downloadURL) => {
				callback(downloadURL)
			})
			.catch((error) => {
				console.log('File upload error:', error)
			})
	}
}

export const listenForRemoveRow = (callback) => {
	const listener = tableDatabaseRef.on('child_removed', (data) => {
		const removedChild = { id: data.key, ...data.val() }
		callback(removedChild)
	})

	return () => {
		databaseRef.off('child_removed', listener)
	}
}

export const listenForAddRow = (callback) => {
	let initialDataLoaded = false
	const listener = tableDatabaseRef.on('child_added', (data) => {
		if (initialDataLoaded) {
			const newChild = { id: data.key, ...data.val() }
			callback(newChild)
		}
	}, (error) => {
		console.log('database access error_1', error)
	})

	tableDatabaseRef.once('value', (snapshot) => {
		initialDataLoaded = true
	}, (error) => {
		console.log('database access error_2', error)
	})

	return () => {
		tableDatabaseRef.off('child_added', listener)
	}
}

export const listenForChangeTable = (callback) => {
	const listener = tableDatabaseRef.on('child_changed', (data) => {
		const updatedChild = { id: data.key, ...data.val() }
		callback(updatedChild)
	})

	return () => {
		tableDatabaseRef.off('child_changed', listener)
	}
}

export const fetchTableData = async (limit, callback) => {
	try {
		const snapshot = await tableDatabaseRef.limitToLast(limit).once('value')
		const tableData = snapshot.val()
		const dataArray = tableData ? Object.entries(tableData).map(([ id, data ]) => ({ id, ...data })) : []
		callback(dataArray)
	} catch (error) {
		console.error('Error fetching tableData:', error)
	}
}

export const updateTable = async (key, path, value) => {
	try {
		const updates = {}
		updates[key + path] = value
		await tableDatabaseRef.update(updates, () => {
			console.log('Table value updated successfully')
		})
	} catch (error) {
		console.error('Error updating Table:', error)
	}
}

export const addRow = async (rowData) => {
	try {
		const key = await tableDatabaseRef.push(rowData)
		return 	key
	} catch (error) {
		console.error('Error row adding', error)
	}
}

export const removeRow = async (rowId) => {
	try {
		await tableDatabaseRef.child(rowId).remove()
		console.log('Row deleted successfully')
	} catch (error) {
		console.error('Post deletion error:', error)
	}
}
