import React, { useEffect, useState } from 'react'
import { fetchTableData, listenForChangeTable, updateTable, addRow, listenForAddRow, removeRow, listenForRemoveRow } from '../../chatApi/chatApi'
import Modal from '../../components/Modal/Modal'
import './table.scss'

const rowLimit = 10

const tableColumns = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "age", label: "Alter" },
  { key: "country", label: "Land" },
  { key: "job", label: "Beruf" }
]

const Table = () => {

	const [ tableData, setTableData] = useState([])
	const [editingCell, setEditingCell] = useState({})
	const [selectedRowId, setSelectedRowId] = useState('')
	const [newRowData, setNewRowData] = useState({})
	const [isOpenModal, setIsOpenModal] = useState(false)

	useEffect(() => {
		fetchTableData(
			rowLimit,
			dataArray => {
				setTableData(dataArray)
			}
		)
	}, [])

		/* Listener for Table changes */
		useEffect(() => {
			const subscribe = listenForChangeTable(updatedItem => {
				if (updatedItem) {
					setTableData(prevData => prevData.map(item =>
						item.id === updatedItem.id ? { ...item, ...updatedItem } : item
					))
				}
			})
			return () => subscribe()
		}, [])

		/* Listener for add new row */
		useEffect(() => {
			const subscribe = listenForAddRow(newRow => {
				if (newRow) {
					setTableData(prevData => [ ...prevData, newRow])
				}
			})

			return () => subscribe()
		}, [])

			/* Listener for remove row */
		useEffect(() => {
			const subscribe = listenForRemoveRow(removedRow => {
				if (removedRow) {
					setTableData(prevData => prevData.filter(item =>
						item.id !== removedRow.id
					))
				}
			})

			return () => subscribe()
		}, [])

	const isEditable = (columnKey) => {
		return columnKey === 'country' || columnKey === 'job'
	}

	const handleChange = (event, rowId, key) => {
		const newValue = event.target.value
		updateTableData(rowId, key, newValue)
	}

	const updateTableData = async (rowId, key, newValue) => {
		await updateTable(rowId, `/${key}`, newValue)
	}

	const onInputNewRow = (event) => {
		const { name, value } = event.target
		setNewRowData(prev => ({ ...prev, [name]: value }))
	}

	const addNewRow = async () => {
		const response = await addRow(newRowData)
		console.log('newRowId', response.key)
	}

	const deleteRow = async () => {
		const response = await removeRow(selectedRowId)
		setIsOpenModal(false)
	}

	const openModal = (e, rowId) => {
		e.preventDefault()
		setSelectedRowId(rowId)
		setIsOpenModal(true)
	}

	const closeModal = () => {
		setIsOpenModal(false)
	}

	return (
		<div className="table-page">
			<div className="table-page__header">
				<span className="table-page__title">Test Table</span>
			</div>
			<table className="table-page__table">
				<thead>
					<tr>
						<th className="table-page__table-cell">
							Remove row
						</th>
						{
							tableColumns.map(column => {
								return (
									<th key={column.key} className="table-page__table-cell">
										{ column.label }
									</th>
								)
							})
						}
					</tr>
				</thead>
				 <tbody>
					{
						tableData.map((row)=> {
							return (
								<tr key={row.id}>
									<td className="table-page__table-cell">
										<button
											className="table-page__table-cell--remove"
											onClick={(e) => openModal(e, row.id)}
										>
											&#10006;
										</button>
									</td>
									{
										tableColumns.map(column => {
											return (
												<td
													key={column.key}
													className={`table-page__table-cell ${isEditable(column.key) && 'active'}`}
													onClick={() => setEditingCell({ rowId: row.id, key: column.key})}
													// onContextMenu={(e) => openModal(e, row.id)}
												>
													{ editingCell.rowId === row.id && editingCell.key === column.key && isEditable(column.key)
														? (
															<input
																type="text"
																value={row[column.key]}
																// autoFocus
																onChange={(e) => handleChange(e, row.id, column.key)}
																onBlur={() => setEditingCell({})}
																onKeyDown={(e) => e.key === "Enter" && setEditingCell({})}
															/>
														) : (
															<div>{row[column.key]}</div>
														)}
												</td>
											)
										})
									}
								</tr>
							)
						})
					}
				</tbody>
  		</table>

			<div className="table-page-submit">

				<label htmlFor="name">
					name
				</label>
				<input name="name" type="text" onChange={onInputNewRow}/>

				<label htmlFor="age">
					age
				</label>
				<input name="age" type="text" onChange={onInputNewRow}/>

				<label htmlFor="job">
					job
				</label>
				<input name="job" type="text" onChange={onInputNewRow}/>

				<label htmlFor="country">
					country
				</label>
				<input name="country" type="text" onChange={onInputNewRow} />
				<button className="table-page-submit-btn" onClick={addNewRow}>
					Add row +
				</button>
			</div>
			{
				isOpenModal &&
				<Modal
					title="Remove this row?"
					okButtonText="Отменить"
					onClickOk={closeModal}
					cancelButtonText="Remove"
					onClickCancel={deleteRow}
				>
				</Modal>
			}
		</div>
	)
}

export default Table
