export const SET_LOGIN = 'SET_LOGIN'
export const SET_LOGIN_WITH_CREDENTIALS = 'SET_LOGIN_WITH_CREDENTIALS'
export const SET_LOGOUT = 'SET_LOGOUT'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const SET_POSTS_CACHE = 'SET_POSTS_CACHE'
export const ADD_POST_CACHE = 'ADD_POST_CACHE'
export const UPDATE_POST_CACHE = 'UPDATE_POST_CACHE'
export const REMOVE_POST_CACHE = 'REMOVE_POST_CACHE'
export const RESET_UNREAD_MESSAGES = 'RESET_UNREAD_MESSAGES'
export const SET_UNREAD_MESSAGE = 'SET_UNREAD_MESSAGE'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const SET_LAST_LOADED_POST_ID = 'SET_LAST_LOADED_POST_ID'
